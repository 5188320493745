<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-end justify-content-end p-0 bg-container"
      >
        <b-img
          class="reset-bg"
          fluid
          :src="imgUrl"
          alt="Forgot password V2"
          style="width: 86.9%;"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Forgot Password?
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                class="reset-btn border-0"
                type="submit"
                variant="primary"
                block
              >
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
        <b-card-text class="font-small-1 login-footer">
          ©{{ currentYear }} Circle Of Care. All Rights Reserved. Powered by <b-link
            href="https://www.motivit.com"
            target="_blank"
          >MotivIT</b-link>
        </b-card-text>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-reset-coc.png'),
      // validation
      required,
      email,
      currentYear: new Date().getFullYear(),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal.showLoading()
          useJwt.axiosIns.post(`${this.$apiUrl2}auth`, {
            email: this.userEmail,
            pass: this.password,
            action: 'check_email',
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          })
            .then(response => {
              const result = response.data.response.data
              // console.log( result )
              if (result === 1) {
                this.send_Email()
              } else {
                this.$swal({
                  title: 'Warning!',
                  text: 'Email does not exist!',
                  allowOutsideClick: false,
                  icon: 'warning',
                  customClass: {
                    confirmButton: 'pop-up-btn w-25',
                  },
                  buttonsStyling: false,
                })
              }
            })
            .catch(() => {
              this.$swal({
                title: 'Operation Failed!',
                text: 'Please Try Again',
                allowOutsideClick: false,
                icon: 'error',
                customClass: {
                  confirmButton: 'pop-up-btn w-25',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    send_Email() {
      useJwt.axiosIns.post(`${this.$apiUrl2}auth`, {
        email: this.userEmail,
        path: window.location.origin,
        action: 'send_email',
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          const res = response.data.response.data
          if (res === 1) {
            this.$swal({
              title: 'Success!',
              html: 'Please check your email to reset your <br>account password',
              icon: 'success',
              allowOutsideClick: false,
              showCancelButton: false,
              customClass: {
                confirmButton: 'btn btn-secondary pop-up-btn w-25',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                window.location.replace('/')
              }
            })
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Operation Failed!',
            text: 'Please Try Again',
            allowOutsideClick: false,
            icon: 'error',
            customClass: {
              confirmButton: 'pop-up-btn w-25',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
